<template>
    <div class="goods">
        <!--:style="bgimgstyle"-->
        <div class="goodsbg">
            <div class="banner">
                <van-swipe class="myswipe" :autoplay="3000" indicator-color="white">
                    <van-swipe-item v-for="item in detailInfo.photo" :key="item.id">
                        <img class="bannerimg" :src="item.url"/>
                    </van-swipe-item>
                </van-swipe>
                <!-- <img class="bannerimg" :src="require('@/assets/images/index/ept_bg.png')" /> -->
            </div>
            <div class="navcell">
                <img
                        @click="clickBackPage"
                        class="navicon"
                        :src="require('@/assets/images/mall/goods/backicon.png')"
                />
                <div class="rightview">
                    <img
                            @click="clickshare"
                            class="navicon marginR24"
                            :src="require('@/assets/images/mall/goods/shareicon.png')"
                    />
                    <!-- <img class="navicon" :src="require('@/assets/images/mall/goods/collecticon.png')" /> -->
                </div>
            </div>
        </div>
        <div class="goodinfo">
            <div class="price">
                 <span class="numprice">${{ parseFloat(detailInfo.price).toFixed(2) }}≈￥{{ parseFloat(detailInfo.price*cny_to_us).toFixed(2) }}</span>
                <span class="pricetip">单价</span>
            </div>
            <div class="goodsname van-multi-ellipsis--l2">
                {{ detailInfo.name }}
            </div>
            <div class="goodsdes van-multi-ellipsis--l2">
                {{ detailInfo.brief }}
            </div>
            <div class="runinfo">
                <div class="run marginR58">
                    运费：{{ parseFloat(detailInfo.freight_price).toFixed(2) }}USD
                </div>
                <div class="run marginR58">销量：{{ detailInfo.buy_count }}</div>
                <div class="run">库存：{{ detailInfo.stock }}</div>
            </div>
            <div class="exceptional">
                <div class="person">
                   <!-- <div class="personimg">
                        <img
                                class="headimg"
                                v-for="(p, index) in detailInfo.photo"
                                :key="index"
                                :src="p.url"
                        />
                    </div>-->
                    <div class="numtxt">
                        有{{
                        parseFloat(detailInfo.reward_has_total).toFixed(0)
                        }}人已获得返利打赏
                    </div>
                </div>
                <div class="exceptetip">
                    购买商品可获得{{ parseFloat(detailInfo.reward_num).toFixed(2)
                    }}{{ detailInfo.reward_coin_symbol }}打赏，商品总打赏剩余
                    {{
                    (parseFloat(detailInfo.reward_total)-parseFloat(detailInfo.reward_has_total)).toFixed(2)
                    }}{{ detailInfo.reward_coin_symbol }}
                </div>
            </div>
        </div>
        <div class="payway">
            <div class="paytxt">选择兑换方式</div>

            <div class="paybox">
                <template v-for="pay in detailInfo.buy_type.split(',')">
                    <div v-if="pay==1"
                            :class="pay_way == 'ATT' ? 'payselectbtn' : 'paynomalbtn'"
                            @click="choicepayway('ATT')"
                    >
                        通道A
                    </div>
                    <div v-if="pay==2"
                            :class="pay_way == 'EPT' ? 'payselectbtn' : 'paynomalbtn'"
                            @click="choicepayway('EPT')"
                    >
                        通道E
                    </div>
                    <div v-if="pay==3"
                            :class="pay_way == 'ARR' ? 'payselectbtn' : 'paynomalbtn'"
                            @click="choicepayway('ARR')"
                    >
                        通道R
                    </div>
                    <div v-if="pay==4"
                            :class="pay_way == 'USDT' ? 'payselectbtn' : 'paynomalbtn'"
                            @click="choicepayway('USDT')"
                    >
                        通道U
                    </div>
                </template>


            </div>
        </div>
        <div class="card">
           <!-- <div class="specifications">
                <div class="lefttxt">规格</div>
                <div class="contenttxt">请选择商品规格</div>
                <van-icon name="arrow" color="#C4C4C4"/>
            </div>-->
            <div class="specifications">
                <div class="lefttxt">保障</div>
                <div class="guarantee">
                    <div class="gitem">
                        <img
                                class="gicon"
                                :src="require('@/assets/images/mall/goods/guarantee.png')"
                        />
                        <div class="gtxt">正品联保</div>
                    </div>
                    <div class="gitem">
                        <img
                                class="gicon"
                                :src="require('@/assets/images/mall/goods/guarantee.png')"
                        />
                        <div class="gtxt">联盟商家</div>
                    </div>
                </div>
                <van-icon name="arrow" color="#C4C4C4"/>
            </div>
            <div class="sendgoods">
                <div class="lefttxt">发货</div>
                <div class="contenttxt">购买后48小时内根据位置...</div>
                <van-icon name="arrow" color="#C4C4C4"/>
            </div>
        </div>
        <div class="card merchantscard">
            <div class="merchants">{{ detailInfo.store.store_name }}</div>
            <div class="merchantdes" v-html="detailInfo.store.desc">
            </div>
        </div>
        <div class="card merchantscard">
            <div class="evaluation">
                <div class="title">商品评价</div>
                <div class="percentage" @click="goCommentPage">
                    好评 >
                    <!-- <span style="color: #ffb936">92%</span> > -->
                </div>
            </div>
            <div class="evaswiper" v-if="detailInfo.comment.length > 0">
                <van-swipe :loop="false" :width="300" :show-indicators="false">
                    <div v-for="(com, index) in detailInfo.comment" :key="index">
                        <van-swipe-item v-if="com.is_show == 1">
                            <div class="evabox">
                                <div class="rank">
                                    <div class="img"></div>
                                    <div class="name">Dosence</div>
                                    <img
                                            v-for="star in com.goods_rank"
                                            :key="star"
                                            class="staricon"
                                            :src="require('@/assets/images/mall/starorange.png')"
                                    />
                                </div>
                                <div class="comtxt van-multi-ellipsis--l3">
                                    整体评价：{{ com.content }}
                                </div>
                            </div>
                        </van-swipe-item>
                    </div>
                </van-swipe>
            </div>
        </div>

        <!--:style="bgimgstyle"-->
        <div class="card_goodDetail" v-html="detailInfo.intro"></div>
        <div class="paybottom">
            <div class="home marginR48" @click="goHome">
                <img
                        class="houseicon"
                        :src="require('@/assets/images/mall/goods/house.png')"
                />
                <div>首页</div>
            </div>
            <!--@click="clickTel"-->
            <div class="home marginR58" @click="clickTel">
                <img
                        class="houseicon"
                        :src="require('@/assets/images/mall/goods/telicon.png')"
                />
                <div>联系商家</div>
            </div>
            <div class="payinfo">
                <div class="payprice">
                    {{ pay_amount }}
                    <!--<span class="smalltxt">/{{ pay_way }}</span>-->
                    <!--// parseFloat(detailInfo.price).toFixed(2)-->
                </div>
                <!--showpwd=true-->
                <!--@click="noShow"-->
                <div class="paybtn" @click="showpwd=true">兑换</div>
            </div>
        </div>
        <van-dialog
                v-model="showpwd"
                title="确认下单"
                @cancel="pwdShow"
                @confirm="clickbuyOrder"
                show-cancel-button
        >
            <van-field
                    type="password"
                    v-model="password"
                    name="安全密码"
                    label="安全密码"
                    placeholder="请输入安全密码"
            ></van-field>
        </van-dialog>
        <sharebox ref="sharebox"></sharebox>
    </div>
</template>

<script>
    import sharebox from "./actionsheet/sharebox.vue";
    import {getGoodsDetail, createOrder} from "@/request/shopapi";
    import {duiConfig,getOption} from "@/request/api";
    import {Toast, Dialog} from "vant";

    export default {
        components: {
            sharebox,
        },
        data() {
            return {
                cny_to_us:0,
                persons: [1, 2, 3, 4],
                detailInfo: {
                    comment: [],
                    store: {
                        store_name: "",
                    },
                },
                goodid: "",
                pay_way: "ATT",
                bgimgstyle: {
                    backgroundImage: "url(https://testv2.eptswap.com/uploads/file/jiu.jpg)",
                },
                showpwd: false,
                password: "",
                address: "",
                userInfo: {},
                config: {
                    ept_price: "0.15",
                    arr_price: "2000",
                    et_price: "1",
                },
                pay_amount: 0,
            };
        },
        activated() {
            this.goodid = this.$route.query.id;
            this.address = sessionStorage.getItem("address");
            this.userInfo = sessionStorage.getItem("user")
                ? JSON.parse(sessionStorage.getItem("user"))
                : {};
            this.getduiConfig();
            this.requestgoodDetail();
            getOption({name: 'cny_to_us'}).then(res=> {
                this.cny_to_us = res.data
            })
        },

        methods: {
            clickshare() {
                this.$refs.sharebox.preview(this.detailInfo);
            },
            noShow() {
                Toast("暂未开放");
                return;
            },
            getduiConfig() {
                duiConfig().then((res) => {
                    this.config = res.data;
                });
            },
            clickbuyOrder() {
                let _this = this;
                if (!this.password) {
                    Toast("请输入密码");
                    return;
                }
                if (!this.address) {
                    Toast("请使用币安智能链链接");
                    return;
                }
                if (this.address && this.userInfo.has_paypwd != 1) {
                    Toast("请先完善资料,设置安全密码");
                    setTimeout(function () {
                        _this.$router.push({
                            path: "/home/mine",
                        });
                    });
                    return;
                }
                this.requestcreateOrder();
            },
            pwdShow() {
                this.showpwd = false;
            },
            clickTel() {
                Dialog.confirm({
                    title: "拨打电话",
                    message: "您确定拨打" + this.detailInfo.store.mobile + "?",
                    confirmButtonText: "确认",
                    confirmButtonColor: "#FF7220",
                })
                    .then(() => {
                        const a = document.createElement("a");
                        a.href = "tel:" + this.detailInfo.store.mobile;
                        a.click();
                        // on confirm
                    })
                    .catch(() => {
                        // on cancel
                    });
            },
            goHome() {
                this.$router.replace({
                    path: "/mall/mallhome",
                });
            },
            choicepayway(waytxt) {
                this.pay_way = waytxt;
                if (waytxt == "ATT") {
                    this.pay_amount = parseFloat(
                        this.detailInfo.price / this.config.et_price
                    ).toFixed(4);
                } else if (waytxt == "EPT") {
                    this.pay_amount = parseFloat(
                        this.detailInfo.price / this.config.ept_price
                    ).toFixed(4);
                } else if (waytxt == "ARR") {
                    this.pay_amount = parseFloat(
                        this.detailInfo.price / this.config.arr_price
                    ).toFixed(4);
                }
                else if (waytxt == "USDT") {
                    this.pay_amount = parseFloat(
                        this.detailInfo.price/ this.config.usdt_price
                    ).toFixed(4);
                }
            },
            requestcreateOrder() {
                const params = {
                    address: this.address,
                    pay_code: this.password,
                    good_id: this.goodid,
                    pay_way: this.pay_way,
                };
                createOrder(params).then((res) => {
                    let success = res.success;
                    Toast(res.msg);
                    if (success) {
                        this.$router.push({
                            path: "/mall/paypage",
                            query: {
                                id: res.data,
                            },
                        });
                    }
                });
            },
            requestgoodDetail() {
                getGoodsDetail({id: this.goodid}).then((res) => {
                    this.detailInfo = res.data;
                    this.pay_amount = parseFloat(
                        this.detailInfo.price / this.config.et_price
                    ).toFixed(4);
                });
            },
            clickBackPage() {
                this.$router.back();
            },
            goCommentPage() {
                this.$router.push({
                    path: "/mall/commentlist",
                    query: {
                        id: this.goodid,
                    },
                });
            },
        },
    };
</script>

<style scoped lang="scss">
    .banner {
        /*padding: 0px 32px;*/
        display: flex;
        justify-content: center;
        .myswipe {
            .bannerimg {
                width: 750px;
                height: 750px;
            }
        }
    }

    .marginR24 {
        margin-right: 24px;
    }

    .marginR58 {
        margin-right: 58px;
    }

    .marginR48 {
        margin-right: 48px;
    }

    .marginT16 {
        margin-top: 16px;
    }

    .goods {
        background: #ebeef2;
        min-height: 100vh;
        padding-bottom: 16px;
        .goodsbg {
            width: 750px;
            height: 750px;
            position: relative;
            background-color: #e3d2bf;
            /*padding-top: 88px;*/
            background-size: cover;
        }
        .navcell {
            padding: 0 32px;
            width: 750px;
            height: 88px;
            z-index: 999px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            left: 0;
            top: 0;
            .navicon {
                width: 64px;
                height: 64px;
            }
            .rightview {
                display: flex;
            }
        }
        .goodinfo {
            width: 750px;
            min-height: 466px;
            border-radius: 24px;
            opacity: 1;
            background: rgba(255, 255, 255, 1);
            padding: 16px 32px;
            .price {
                .numprice {
                    color: rgba(255, 114, 32, 1);
                    font-weight: 500;
                    font-family: "DIN";
                    text-align: justify;
                    margin-right: 12px;
                    font-size: 48px;
                }
                .pricetip {
                    color: rgba(122, 122, 122, 1);
                    font-size: 24px;
                    font-weight: 400;
                    font-family: "PingFang SC";
                    text-align: center;
                    line-height: 36px;
                }
            }
            .goodsname {
                opacity: 1;
                color: rgba(41, 33, 29, 1);
                font-size: 28px;
                font-weight: 700;
                font-family: "PingFang SC";
                text-align: left;
                line-height: 40px;
            }
            .goodsdes {
                margin-top: 8px;
                color: rgba(105, 98, 95, 1);
                font-size: 24px;
                font-weight: 400;
                font-family: "PingFang SC";
                text-align: left;
                line-height: 36px;
            }
            .runinfo {
                display: flex;
                justify-content: start;
                margin-top: 24px;
                .run {
                    min-width: 160px;
                    height: 36px;
                    opacity: 1;
                    color: rgba(156, 152, 150, 1);
                    font-size: 24px;
                    font-weight: 400;
                    font-family: "PingFang SC";
                    text-align: left;
                    line-height: 36px;
                }
            }
            .exceptional {
                margin-top: 16px;
                padding: 16px 18px;
                width: 686px;
                height: 124px;
                border-radius: 16px;
                opacity: 1;
                background: rgba(255, 247, 242, 1);
                .person {
                    display: flex;
                    align-items: center;
                    .personimg {
                        display: flex;
                        margin-right: 8px;
                        .headimg {
                            width: 48px;
                            height: 48px;
                            opacity: 1;
                            border: 2px solid rgba(255, 255, 255, 1);
                            background-color: #e3d2bf;
                            border-radius: 24px;
                            position: relative;
                        }
                    }
                    .numtxt {
                        color: rgba(95, 49, 6, 1);
                        font-size: 24px;
                        font-weight: 500;
                        font-family: "PingFang SC";
                        text-align: left;
                        line-height: 48px;
                    }
                }
                .exceptetip {
                    margin-top: 8px;
                    color: rgba(105, 98, 95, 1);
                    font-size: 24px;
                    font-weight: 400;
                    font-family: "PingFang SC";
                    text-align: left;
                }
            }
        }
        .payway {
            padding: 24px 32px;
            margin-top: 16px;
            width: 750px;
            min-height: 168px;
            border-radius: 24px;
            opacity: 1;
            background: rgba(255, 255, 255, 1);
            .paytxt {
                color: rgba(41, 33, 29, 1);
                font-size: 28px;
                font-weight: 700;
                font-family: "PingFang SC";
                text-align: left;
                line-height: 40px;
            }
            .paybox {
                display: flex;
                justify-content: flex-start;
                margin-top: 16px;
                .payselectbtn {
                    width: 150px;
                    height: 64px;
                    border-radius: 8px;
                    opacity: 1;
                    border: 2px solid rgba(255, 114, 32, 1);
                    background: rgba(255, 247, 242, 1);
                    color: rgba(255, 114, 32, 1);
                    font-size: 28px;
                    font-weight: 400;
                    font-family: "PingFang SC";
                    text-align: center;
                    line-height: 64px;
                    margin-right: 5%;
                }
                .paynomalbtn {
                    width: 150px;
                    height: 64px;
                    border-radius: 8px;
                    opacity: 1;
                    background: rgba(250, 247, 245, 1);
                    color: rgba(105, 98, 95, 1);
                    font-size: 28px;
                    font-weight: 400;
                    font-family: "PingFang SC";
                    text-align: center;
                    line-height: 64px;
                    margin-right: 5%;
                }
            }
        }
        .card_goodDetail{
            margin-top: 16px;
            padding: 32px 32px;
            width: 750px;
            min-height: 176px;
            border-radius: 24px;
            opacity: 1;
            background: rgba(255, 255, 255, 1);
            img{
                width: 100% !important;
            }
        }
        .card {
            margin-top: 16px;
            padding: 0px 32px;
            width: 750px;
            min-height: 176px;
            border-radius: 24px;
            opacity: 1;
            background: rgba(255, 255, 255, 1);
            .specifications {
                display: flex;
                justify-content: space-between;
                align-items: center;
                min-height: 80px;
                border-bottom: 1px solid #c4c4c4;
                font-size: 24px;
                font-weight: 400;
                font-family: "PingFang SC";
                text-align: left;
                .guarantee {
                    width: 300px;
                    display: flex;
                    justify-content: space-around;
                    padding: 24px 0px;
                    .gitem {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .gicon {
                            width: 40px;
                            height: 40px;
                        }
                    }
                }
            }
            .sendgoods {
                display: flex;
                justify-content: space-between;
                align-items: center;
                min-height: 80px;
                font-size: 24px;
                font-weight: 400;
                font-family: "PingFang SC";
                text-align: left;
            }
            .merchants {
                color: rgba(41, 33, 29, 1);
                font-size: 24px;
                font-weight: 400;
                font-family: "PingFang SC";
                text-align: left;
                line-height: 36px;
            }
            .merchantdes {
                margin-top: 8px;
                color: rgba(105, 98, 95, 1);
                font-size: 24px;
                font-weight: 400;
                font-family: "PingFang SC";
                text-align: left;
                line-height: 36px;
            }
            .evaluation {
                display: flex;
                justify-content: space-between;
                .title {
                    color: rgba(41, 33, 29, 1);
                    font-size: 28px;
                    font-weight: 700;
                    font-family: "PingFang SC";
                    text-align: left;
                }
                .percentage {
                    color: rgba(122, 122, 122, 1);
                    font-size: 24px;
                    font-weight: 500;
                    text-align: right;
                    font-family: "PingFang SC";
                }
            }
            .evaswiper {
                margin-top: 16px;
                .evabox {
                    width: 506px;
                    height: 210px;
                    border-radius: 8px;
                    opacity: 1;
                    background: rgba(250, 247, 245, 1);
                    padding: 16px;
                    .rank {
                        display: flex;
                        align-items: center;
                        .img {
                            width: 48px;
                            height: 48px;
                            border-radius: 24px;
                            background-color: orange;
                            margin-right: 16px;
                        }
                        .name {
                            color: rgba(52, 52, 52, 1);
                            font-size: 24px;
                            font-weight: 700;
                            font-family: "PingFang SC";
                            margin-right: 8px;
                        }
                        .staricon {
                            width: 24px;
                            height: 24px;
                            margin-right: 10px;
                        }
                    }
                    .comtxt {
                        margin-top: 20px;
                        color: rgba(87, 87, 87, 1);
                        font-size: 24px;
                        font-weight: 400;
                        font-family: "PingFang SC";
                        text-align: left;
                        line-height: 40px;
                    }
                }
            }
        }
        .merchantscard {
            padding: 32px;
        }
        .paybottom {
            padding: 16px 10px 16px 32px;
            display: flex;
            width: 750px;
            height: 112px;
            opacity: 1;
            background: rgba(250, 247, 245, 1);
            box-shadow: 0 -8px 8px 0 rgba(80, 29, 0, 0.12);
            .home {
                color: rgba(105, 98, 95, 1);
                font-size: 20px;
                font-weight: 400;
                font-family: "PingFang SC";
                text-align: center;
                .houseicon {
                    width: 48px;
                    height: 48px;
                }
            }
            .payinfo {
                padding-left: 16px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 440px;
                height: 80px;
                border-radius: 24px 74px 74px 24px;
                opacity: 1;
                border: 2px solid rgba(241, 193, 193, 1);
                background: rgba(250, 240, 240, 1);
                .payprice {
                    color: rgba(241, 27, 27, 1);
                    font-weight: 500;
                    text-align: left;
                    font-size: 36px;
                    font-family: "DIN";
                    line-height: 44px;
                    .smalltxt {
                        color: rgba(241, 27, 27, 1);
                        font-weight: 400;
                        text-align: left;
                        font-size: 24px;
                        font-family: "PingFang SC";
                        line-height: 36px;
                    }
                }
                .paybtn {
                    width: 220px;
                    height: 80px;
                    border-radius: 74px;
                    opacity: 1;
                    background: rgba(255, 114, 32, 1);
                    color: rgba(238, 238, 238, 1);
                    font-size: 28px;
                    font-weight: 700;
                    font-family: "PingFang SC";
                    text-align: center;
                    line-height: 80px;
                }
            }
        }
    }
</style>
