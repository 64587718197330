<template>
  <div>
    <van-popup class="popclass" v-model="show">
      <div class="content">
        <img class="pic" :src="info.images" />
        <div class="detail">
          <div class="txtbox">
            <div class="tip van-multi-ellipsis--l2">{{ info.name }}</div>
            <div class="price">
              {{ parseFloat(info.price).toFixed(2)
              }}<span class="unit">/USD</span>
              <span class="unit" style="color: rgba(122, 122, 122, 1)"
                >单价</span
              >
            </div>
          </div>
          <div class="code">
            <vue-qr
              :text="qrurl"
              :margin="0"
              colorDark="black"
              colorLight="white"
            ></vue-qr>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import VueQr from "vue-qr";
export default {
  name: "EtWebSharebox",
  components: {
    VueQr,
  },
  data() {
    return {
      qrurl: "",
      show: false,
      code: "https://www.baidu.com/",
      info: {
        images: "",
      },
    };
  },

  mounted() {},

  methods: {
    preview(item) {
      this.info = item;
      this.show = true;
      this.qrurl =
        process.env.VUE_APP_BASE_API +
        "/app/index.html#/mall/goodsdetail?id=" +
        item.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 12px;
  background: white;
  .pic {
    width: 552px;
    height: 552px;
    border-radius: 24px;
  }
  .detail {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    .txtbox {
      width: 374px;
      .tip {
        color: rgba(41, 33, 29, 1);
        font-size: 28px;
        font-weight: 700;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 40px;
      }
      .price {
        color: rgba(255, 114, 32, 1);
        font-weight: 500;
        font-family: "DIN";
        text-align: justify;
        font-size: 48px;
      }
      .unit {
        font-size: 24px;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: center;
        line-height: 36px;
      }
    }
    .code {
      width: 144px;
      height: 144px;
      opacity: 1;
      img {
        width: 144px;
        height: 144px;
      }
    }
  }
}
</style>
